


















































































import Vue from 'vue'

import { Pagination } from 'vuelpers'
import { Customer } from '@/types'
import { mapActions, mapGetters } from 'vuex'
import { Accessors } from 'vue/types/options'

import FormCustomer from '@/components/Forms/FormCustomer.vue'

export default Vue.extend({
	metaInfo: {
		title: 'Customers | Admin | zx55',
	},
	components: {
		FormCustomer,
	},
	data() {
		return {
			property: 'value',
			headers: [
				{ text: 'Name', value: 'fullName' },
				{ text: 'Email', value: 'user.email' },
				{ text: 'Vat NO', value: 'vVatNo' },
				{ text: 'NewsLetter', value: 'eNewsLetter' },
				{ text: 'Status', align: 'center', value: 'status' },
				{ text: 'Actions', value: 'actions' },
			],
			actions: [
				{
					icon: 'mdi-pencil',
					text: 'Update',
					event: 'update',
				},
				{
					icon: 'mdi-block-helper',
					text: 'Change status',
					event: 'status',
				},
				{
					icon: 'mdi-eye',
					text: 'Pretend as Customer',
					event: 'pretendAsCustomer',
				},
				{
					icon: 'mdi-trash-can',
					text: 'Delete Customer',
					event: 'remove',
				},
			],
			categoryForm: {
				dialog: false,
				data: {},
			},
			confirmDialog: {
				show: false,
				id: null,
				customer: null,
			} as any,

			customer: {
				dialog: false,
				data: null as Customer | null,
			},
		}
	},
	computed: {
		...(mapGetters('customer', ['$customers']) as Accessors<{
			$customers: Pagination<Customer>
		}>),
	},
	methods: {
		...mapActions('auth', ['loginAsCustomer']),
		...mapActions('customer', [
			'getCustomers',
			'getSupplierById',
			'changeStatusById',
			'deleteCustomerById',
		]),
		async onConfirmDeleteCustomer(item: any) {
			this.confirmDialog = {
				...this.confirmDialog,
				show: true,
				customer: item,
			}
		},
		async handleDeleteCustomer() {
			let [err] = await this.deleteCustomerById(
				this.confirmDialog.customer.iCustomerId
			)
			if (!err) {
				this.$notify({
					group: 'foo',
					title: 'Success',
					type: 'success',
					text: 'Customer Deleted Successfully',
				})
				this.confirmDialog = {
					...this.confirmDialog,
					show: false,
					customer: null,
				}
			} else {
				this.$notify({
					group: 'foo',
					title: 'Error',
					type: 'error',
					text: 'An error occured while deleting Customer',
				})
			}
		},
		onInitUpdate(customer: Customer) {
			this.customer = {
				dialog: true,
				data: customer,
			}
		},
		onPretendAsCustomer(customer: Customer) {
			return this.loginAsCustomer(customer)
		},
		onConfirmStatusChange(item: Customer) {
			this.confirmDialog.show = true
			this.confirmDialog.id = item.iCustomerId
		},
		async handleChangeStatus(item: Customer) {
			let [err] = await this.changeStatusById(item.iCustomerId)
			if (!err) {
				this.$notify({
					group: 'foo',
					title: 'Success',
					type: 'success',
					text: 'Customer status has changed Successfully',
				})
				this.confirmDialog.show = false
			} else {
				this.$notify({
					group: 'foo',
					title: 'Error',
					type: 'error',
					text: 'An unexpected error occured',
				})
			}
		},
	},
})
