














































































































































































































import Vue from 'vue'

import { omitEmpties } from 'vuelpers'
import { mapGetters, mapActions } from 'vuex'
import { Accessors } from 'vue/types/options'
import { getValidators } from '@/mixins/FormMixin'
import { User, Role, VFormRef, BackendError, Customer, Status } from '@/types'

const newUser = (v?: User): User => ({
	iUserId: v?.iUserId || 0,
	vFirstName: v?.vFirstName || '',
	vLastName: v?.vLastName || '',
	email: v?.email || '',
	vMobileNumber: v?.vMobileNumber || '',

	password: '',
	password_confirmation: '',

	// Extra
	permissions: v?.permissions || [],
	attachment: v?.attachment || '',
	vIPAddress: v?.vIPAddress || '',
	vProfileImage: v?.vProfileImage || '',
	vRole: Role.Customer,
	eStatus: Status.Active,
	createdAt: v?.createdAt || new Date(),
	updatedAt: v?.updatedAt || new Date(),
})

const newCustomer = (v?: Customer): Customer => ({
	iCustomerId: v?.iCustomerId || 0,
	iUserId: v?.iUserId || 0,
	vCompany: v?.vCompany || '',
	vAccountRef: v?.vAccountRef,
	vVatNo: v?.vVatNo,
	eNewsLetter: v?.eNewsLetter || 'No',
	eHearAbout: v?.eHearAbout,
	bIsTaxIncluded: v?.bIsTaxIncluded || false,
	user: newUser(v?.user),

	createdAt: v?.createdAt || new Date(),
	updatedAt: v?.updatedAt || new Date(),
})

export default Vue.extend({
	name: 'FormCustomer',
	props: {
		value: {
			type: Boolean,
			default: false,
		},
		customer: {
			type: Object as Vue.PropType<Customer>,
		},
	},
	data() {
		return {
			isValid: false,
			isLoading: false,
			errors: {} as BackendError,
			rules: getValidators('required', 'email', 'password', 'match'),
			mCustomer: newCustomer(),

			isVisiblePassword: false,
			isVisiblePasswordConfirmation: false,
		}
	},
	watch: {
		customer: {
			deep: true,
			immediate: true,
			handler(v?: Customer) {
				this.mCustomer = newCustomer(v)
			},
		},
		value: {
			immediate: true,
			handler(v: boolean) {
				if (!v) return
				this.mCustomer = newCustomer(this.customer)
			},
		},
	},
	computed: {
		...(mapGetters('auth', ['$currentUser']) as Accessors<{
			$currentUser: User
		}>),
		formRef(): VFormRef {
			return this.$refs.formRef as VFormRef
		},
		rulesConfirmPassword() {
			return [
				(v: any) => {
					const { password } = this.mCustomer.user
					if (!password) return true
					return this.rules.match(v, password, 'Password')
				},
			]
		},
		isUpdate(): boolean {
			return !!this.mCustomer.iCustomerId
		},
		submitFunction(): (data: any) => Promise<[error: any, response: any]> {
			return this.isUpdate ? this.updateCustomer : this.createCustomer
		},
		mValue: {
			get(): boolean {
				return this.value
			},
			set(v): void {
				this.$emit('input', v)
			},
		},
	},
	methods: {
		...mapActions('customer', ['updateCustomer', 'createCustomer']),
		onResetCustomer() {
			this.formRef.reset()
			this.mValue = false
		},
		async onSubmitCustomer(): Promise<any> {
			if (!this.formRef.validate()) return

			this.isLoading = true
			const [err] = await this.submitFunction(omitEmpties(this.mCustomer))
			this.isLoading = false

			if (err) {
				return this.$toast.error(err.message || 'Error while submitting')
			}

			this.onResetCustomer()
			this.$toast.success('Successfully submitted')
		},
	},
})
